<template>
  <cw-page
    icon="local_offer"
    class="car-loan__loan-offer"
  >
    <validation-observer
      ref="observer"
      tag="v-form"
      autocomplete="off"
    >
      <template #default="{ valid }">
        <v-row>
          <v-col cols="12">
            <h3 class="text-subtitle-1 font-weight-bold mb-1">
              <translate>
                Choose loan amount and monthly installment
              </translate>
            </h3>
            <p>
              <translate>
                If the loan amount or monthly installment changes, the loan offer must be
                recalculated. To get a new offer, press the Calculate button.
              </translate>
            </p>
          </v-col>
          <v-col sm="6" cols="12" class="car-loan__loan-offer__loanAmount--wrapper">
            <cw-text-field
              id="carLoanLimit"
              ref="carLoanLimit"
              v-model="form.carLoanLimit"
              :disabled="!disabled.carInstalmentAmount"
              :hint="carLoanLimitHint"
              :label="carLoanLimitLabel"
              :loading="$wait.waiting('carLoanLimit')"
              :max="rules.limit.max / 100"
              :min="rules.limit.min / 100"
              :rules="
                `required|integer|between:${
                  rules.limit.min / 100
                },${
                  rules.limit.max / 100
                }`
              "
              :type="$showNumbers ? 'number' : 'text'"
              events="input"
              step="100"
              currency-input
              prevent-set-data
              @blur="handleValueChange('carLoanLimit', $event)"
              @focus="handleValueChange('carLoanLimit', $event)"
            >
              <template #append-outer>
                <v-btn
                  id="updateLoanLimit"
                  :disabled="
                    disabled.carLoanLimit
                      || $wait.waiting('updateLoanLimit')
                      || !valid
                  "
                  :loading="$wait.waiting('updateLoanLimit')"
                  color="primary"
                  small
                  @click="
                    refreshOffer('updateLoanLimit', true);
                    $eventLogger.clickEvent($event);
                  "
                >
                  <translate>
                    Count
                  </translate>
                </v-btn>
              </template>
            </cw-text-field>
          </v-col>
          <v-col sm="6" cols="12" class="car-loan__loan-offer__instalmentAmount--wrapper">
            <cw-text-field
              id="carInstalmentAmount"
              ref="carInstalmentAmount"
              v-model="form.carInstalmentAmount"
              :disabled="!disabled.carLoanLimit"
              :hint="carInstalmentAmountHint"
              :label="carInstalmentAmountLabel"
              :loading="$wait.waiting('carInstalmentAmount')"
              :max="rules.instalment.max / 100"
              :min="rules.instalment.min / 100"
              :rules="
                `required|decimal:2|between:${
                  rules.instalment.min / 100
                },${
                  rules.instalment.max / 100
                }`
              "
              :type="$showNumbers ? 'number' : 'text'"
              events="input"
              step="1"
              currency-input
              prevent-set-data
              @blur="handleValueChange('carInstalmentAmount', $event)"
              @focus="handleValueChange('carInstalmentAmount', $event)"
            >
              <template #append-outer>
                <v-btn
                  id="updateInstalment"
                  :disabled="
                    disabled.carInstalmentAmount
                      || $wait.waiting('updateInstalment')
                      || !valid
                  "
                  :loading="$wait.waiting('updateInstalment')"
                  color="success"
                  small
                  @click="
                    refreshOffer('updateInstalment', true);
                    $eventLogger.clickEvent($event);
                  "
                >
                  <translate>
                    Count
                  </translate>
                </v-btn>
              </template>
            </cw-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="relative">
            <div class="cw-application__table">
              <table id="car-loan__loan-offer__table">
                <tbody>
                  <tr
                    v-for="row in items"
                    :key="row.key"
                  >
                    <td>{{ row.name }}</td>
                    <td class="font-weight-medium text-right">
                      <b>{{ row.value }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="text-right mt-4 mb-0">
              <translate>
                Press the Continue button to confirm the loan amount and the monthly installment.
              </translate>
            </p>
            <cw-form-loader v-if="$wait.waiting('updateOffer')"/>
          </v-col>
        </v-row>
      </template>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :dark="!$wait.waiting('SUBMIT_FORM') && !disableSubmit"
        :disabled="$wait.waiting('SUBMIT_FORM') || disableSubmit"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Continue
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import { goToError } from '@shared/mixins';

export default {
  name: 'CwCarLoanApplicationLoanOffer',

  components: {
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    disabled: {
      carInstalmentAmount: false,
      carLoanLimit: false,
    },
    form: {
      carInstalmentAmount: null,
      carLoanLimit: null,
    },
    items: [],
    offer: '',
    templateOffer: '',
  }),

  computed: {
    /**
     * Import user information getter functions from store
     */
    ...mapGetters({
      locale: 'application/locale',
      mobile: 'getMobile',
      offers: 'application/getLoanOffers',
    }),

    disableSubmit() {
      return !Object.values(this.disabled).every(b => b);
    },

    carInstalmentAmountHint() {
      return this.$gettextInterpolate(this.$gettext('Choose a monthly installment from %{ min } to %{ max }.'), {
        max: this.$filters.currency(this.rules.instalment.max, {
          digits: 0,
          locale: this.locale,
        }),
        min: this.$filters.currency(this.rules.instalment.min, {
          digits: 0,
          locale: this.locale,
        }),
      });
    },

    carLoanLimitHint() {
      return this.$gettextInterpolate(this.$gettext('Please select an amount between %{ min } - %{ max }. The amount will be rounded down to the nearest hundred euros.'), {
        max: this.$filters.currency(this.rules.limit.max, {
          digits: 0,
          locale: this.locale,
        }),
        min: this.$filters.currency(this.rules.limit.min, {
          digits: 0,
          locale: this.locale,
        }),
      });
    },

    rules() {
      return {
        instalment: {
          min: this.offer.instalmentAmountMin || 0,
          max: this.offer.instalmentAmountMax || 1,
        },
        limit: {
          min: this.templateOffer.loanLimitMin || 0,
          max: this.templateOffer.loanLimitMax || 1,
        },
      };
    },

    /**
     * Gettext translations
     */
    carInstalmentAmountLabel: vm => vm.$gettext('Monthly Instalment'),
    carLoanLimitLabel: vm => vm.$gettext('Loan amount'),
  },

  async created() {
    this.$wait.start('updateOffer');
    this.$wait.start('carLoanLimit');
    this.$wait.start('carInstalmentAmount');

    try {
      await this.refreshOffer();
      return false;
    } catch (e) {
      return false;
    }
  },

  methods: {
    ...mapActions({
      loadLoanOffers: 'application/loadLoanOffers',
      submitForm: 'application/submit',
    }),

    async handleValueChange(field = null, e) {
      if (!field) {
        this.disabled.carInstalmentAmount = true;
        this.disabled.carLoanLimit = true;
        return;
      }

      const value = parseInt(e.target.value, 10);
      const offerKey = field === 'carLoanLimit' ? 'loanLimit' : 'instalmentAmount';
      const offerValue = parseInt(this.offer[offerKey], 10) / 100;

      if (field && e.type === 'focus') {
        this.disabled[field] = false;
      }

      if (e.type === 'blur' && value === offerValue) {
        this.disabled[field] = true;
      }
    },
    initializeDialog(component, route = '') {
      this.dialogData.route = route;
      this.dialogData.show = true;
    },

    /**
     * Refresh offer
     */
    async refreshOffer(loader = null, update = false) {
      try {
        if (update) {
          this.$wait.start(loader);
          this.$wait.start('updateOffer');

          await this.submit({ routine: 'Modify Car Instalment Loan Offer' });
        }

        const offers = await this.loadLoanOffers();

        this.templateOffer = offers.find(offer => offer.carOfferType === 'Template');
        this.offer = offers.find(offer => offer.carOfferType === 'LoanOffer');

        this.handleValueChange();

        this.items = [{
          name: this.$gettext('Loan amount'),
          value: this.$filters.currency(this.offer.loanLimit, {
            locale: this.locale,
          }),
        }, {
          name: this.$gettext('Monthly Instalment'),
          value: this.$filters.currency(this.offer.instalmentAmount, {
            locale: this.locale,
          }),
        }, {
          name: this.$gettext('Number of monthly installments'),
          value: this.offer.numberOfInstalments,
        }, {
          name: this.$gettext('First due date'),
          value: this.$filters.date(this.offer.firstDueDate, {
            type: 'date',
            locale: this.locale,
          }),
        }, {
          name: this.$gettext('Nominal interest'),
          value: this.$filters.percent(Number(this.offer.interestRate)),
        }, {
          name: this.$gettext('The annual percentage rate'),
          value: this.$filters.percent(Number(this.offer.annualPercentageRate)),
        }, {
          name: this.$gettext('Total interest'),
          value: this.$filters.currency(this.offer.instalmentTotals.interest, {
            locale: this.locale,
          }),
        }, {
          name: this.$gettext('Total loan management costs'),
          value: this.$filters.currency(this.offer.instalmentTotals.fees, {
            locale: this.locale,
          }),
        }, {
          name: this.$gettext('Total amount due'),
          value: this.$filters.currency(this.offer.instalmentTotals.instalmentAmount, {
            locale: this.locale,
          }),
        }];

        // this.form.carInstalmentAmount = this.offer.instalmentAmount / 100;
        this.form.carLoanLimit = this.offer.loanLimit;

        this.form.carInstalmentAmount = this.offer.instalmentAmount;

        this.$wait.end('updateLoanLimit');
        this.$wait.end('updateInstalment');
        this.$wait.end('carInstalmentAmount');
        this.$wait.end('carLoanLimit');
        this.$wait.end('updateOffer');
        return false;
      } catch (e) {
        this.$wait.end('updateLoanLimit');
        this.$wait.end('updateInstalment');
        this.$wait.end('carInstalmentAmount');
        this.$wait.end('carLoanLimit');
        this.$wait.end('updateOffer');
        return false;
      }
    },

    /**
     * Submit step data
     */
    async validateForm() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        this.goToError();
        return;
      }

      await this.submit();
    },

    async submit({ routine } = { routine: 'Submit Car Loan Offer Confirmation' }) {
      this.$wait.start('SUBMIT_FORM');

      const { carLoanLimit, carInstalmentAmount } = this.form;
      const formParams = { carLoanLimit, carInstalmentAmount };
      const routineParams = {
        uri: 'executeRoutine',
        routine,
      };

      try {
        await this.submitForm({
          formParams,
          routineParams,
        });
        this.$wait.end('SUBMIT_FORM');
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },

    termsLink(termName) {
      const { hash } = this.$store.state.route.params;

      return `${process.env.VUE_APP_API_BASE_URL}/appTerms/viewTermsPdf/${hash}/${termName}`;
    },
  },
};
</script>

<style lang="scss">
  .v-application--light .table tr.activeRow:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }

  .paymentDetails {
    line-height: 1.8em;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.54);
  }
</style>
