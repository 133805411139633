var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cw-page',{staticClass:"car-loan__loan-offer",attrs:{"icon":"local_offer"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"id":"submit","dark":!_vm.$wait.waiting('SUBMIT_FORM') && !_vm.disableSubmit,"disabled":_vm.$wait.waiting('SUBMIT_FORM') || _vm.disableSubmit,"loading":_vm.$wait.waiting('SUBMIT_FORM'),"color":"purple-one darken-2"},on:{"click":function($event){_vm.validateForm();
        _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v(" Continue ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"observer",attrs:{"tag":"v-form","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var valid = ref.valid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-subtitle-1 font-weight-bold mb-1"},[_c('translate',[_vm._v(" Choose loan amount and monthly installment ")])],1),_c('p',[_c('translate',[_vm._v(" If the loan amount or monthly installment changes, the loan offer must be recalculated. To get a new offer, press the Calculate button. ")])],1)]),_c('v-col',{staticClass:"car-loan__loan-offer__loanAmount--wrapper",attrs:{"sm":"6","cols":"12"}},[_c('cw-text-field',{ref:"carLoanLimit",attrs:{"id":"carLoanLimit","disabled":!_vm.disabled.carInstalmentAmount,"hint":_vm.carLoanLimitHint,"label":_vm.carLoanLimitLabel,"loading":_vm.$wait.waiting('carLoanLimit'),"max":_vm.rules.limit.max / 100,"min":_vm.rules.limit.min / 100,"rules":("required|integer|between:" + (_vm.rules.limit.min / 100) + "," + (_vm.rules.limit.max / 100)),"type":_vm.$showNumbers ? 'number' : 'text',"events":"input","step":"100","currency-input":"","prevent-set-data":""},on:{"blur":function($event){return _vm.handleValueChange('carLoanLimit', $event)},"focus":function($event){return _vm.handleValueChange('carLoanLimit', $event)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"id":"updateLoanLimit","disabled":_vm.disabled.carLoanLimit
                    || _vm.$wait.waiting('updateLoanLimit')
                    || !valid,"loading":_vm.$wait.waiting('updateLoanLimit'),"color":"primary","small":""},on:{"click":function($event){_vm.refreshOffer('updateLoanLimit', true);
                  _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v(" Count ")])],1)]},proxy:true}],null,true),model:{value:(_vm.form.carLoanLimit),callback:function ($$v) {_vm.$set(_vm.form, "carLoanLimit", $$v)},expression:"form.carLoanLimit"}})],1),_c('v-col',{staticClass:"car-loan__loan-offer__instalmentAmount--wrapper",attrs:{"sm":"6","cols":"12"}},[_c('cw-text-field',{ref:"carInstalmentAmount",attrs:{"id":"carInstalmentAmount","disabled":!_vm.disabled.carLoanLimit,"hint":_vm.carInstalmentAmountHint,"label":_vm.carInstalmentAmountLabel,"loading":_vm.$wait.waiting('carInstalmentAmount'),"max":_vm.rules.instalment.max / 100,"min":_vm.rules.instalment.min / 100,"rules":("required|decimal:2|between:" + (_vm.rules.instalment.min / 100) + "," + (_vm.rules.instalment.max / 100)),"type":_vm.$showNumbers ? 'number' : 'text',"events":"input","step":"1","currency-input":"","prevent-set-data":""},on:{"blur":function($event){return _vm.handleValueChange('carInstalmentAmount', $event)},"focus":function($event){return _vm.handleValueChange('carInstalmentAmount', $event)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"id":"updateInstalment","disabled":_vm.disabled.carInstalmentAmount
                    || _vm.$wait.waiting('updateInstalment')
                    || !valid,"loading":_vm.$wait.waiting('updateInstalment'),"color":"success","small":""},on:{"click":function($event){_vm.refreshOffer('updateInstalment', true);
                  _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v(" Count ")])],1)]},proxy:true}],null,true),model:{value:(_vm.form.carInstalmentAmount),callback:function ($$v) {_vm.$set(_vm.form, "carInstalmentAmount", $$v)},expression:"form.carInstalmentAmount"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"relative"},[_c('div',{staticClass:"cw-application__table"},[_c('table',{attrs:{"id":"car-loan__loan-offer__table"}},[_c('tbody',_vm._l((_vm.items),function(row){return _c('tr',{key:row.key},[_c('td',[_vm._v(_vm._s(row.name))]),_c('td',{staticClass:"font-weight-medium text-right"},[_c('b',[_vm._v(_vm._s(row.value))])])])}),0)])]),_c('p',{staticClass:"text-right mt-4 mb-0"},[_c('translate',[_vm._v(" Press the Continue button to confirm the loan amount and the monthly installment. ")])],1),(_vm.$wait.waiting('updateOffer'))?_c('cw-form-loader'):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }